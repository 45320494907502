import React, { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { graphql } from 'gatsby'
import { mapEdgesToNodes } from '../lib/helpers'
import styled from 'styled-components'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Hero from '../components/hero-landing'
import Layout from '../containers/layout'
import { Paragraph } from '../styles/Typography'
import PageTitle from '../components/page-title'

export const query = graphql`
  query BlogPageQuery {
    posts: allSanityPost(limit: 12, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    desktop: file(relativePath: { eq: "cookeatdiscover_2019_0230.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
  }
`

const BlogIntro = styled(Paragraph)`
  margin-top: 2rem;
`

const BlogPage = props => {
  const titleRef = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline()
      .to(titleRef.current, {
        autoAlpha: 1,
        delay: 0.5
      })
  })
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)
  const backgroundImageData = data && data.desktop.childImageSharp.fluid

  return (
    <>
      <SEO title='Blog' />
      <Hero imageData={backgroundImageData}>Blog</Hero>
      <Container>
        {/* <h1 className={responsiveTitle1}>Blog</h1> */}
        {/* <PageTitle ref={titleRef}>Here's a collection of articles written by me covering all aspects of my journey as a nutritionist.</PageTitle> */}
        <PageTitle ref={titleRef}>Please check back later, blog posts coming soon!.</PageTitle>

        {/* {postNodes && postNodes.length > 0 && <BlogPostPreviewGrid nodes={postNodes} />} */}
      </Container>
    </>
  )
}

export default BlogPage

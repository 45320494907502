import React from 'react'
import styled from 'styled-components'
import { Heading2 } from '../styles/Typography'

const PageTitleWrapper = styled.div`
  text-align: center;
  padding: 2rem 0 1rem 0;
`

const StyledHeading2 = styled(Heading2)`
  visibility: hidden;
`

const PageTitle = React.forwardRef((props, ref) => (

  <PageTitleWrapper>
    <StyledHeading2 ref={ref}>{props.children}</StyledHeading2>
  </PageTitleWrapper>

))

export default PageTitle

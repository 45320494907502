import { Link } from 'gatsby'
import React from 'react'
import { buildImageObj, cn, getBlogUrl } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockText from './block-text'
import styled from 'styled-components'
import { Heading3 } from '../styles/Typography'

const BlogPostLink = styled(Link)`
  display: block;
  color: inherit;
  text-decoration: none;
  position: relative;
  box-shadow: 0.2rem 0.2rem 0.5rem #e1e1e1;
  border: solid 1px #e1e1e1;
  border-radius: 1rem;

  :hover {
    text-decoration: inherit;
    font-weight: normal;
    img {
      transform: scale(1.05);
    }
    .postDescription {
      transform: translateY(-2px);
    }
  }
`

const LeadMediaThumb = styled.div`
  position: relative;
  padding-bottom: 66.666%;
  background: #eee;
  overflow: hidden;
  border-radius: 1rem 1rem 0 0;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    transition: all 0.2s ease-out;
  }
`

const Excerpt = styled.div`
  & p {
    margin: 0.5em 0;
    font-size: 0.875rem;
  }

  & strong {
    font-weight: 600;
  }
`

const BlogPreviewDescription = styled.div`
  padding: 1rem 1rem 1rem;
  /* position: absolute; */
  /* bottom: 0; */
  /* background: #ffffffe3; */
  /* margin: 1rem; */
  transition: all 0.2s ease-out;
  /* left: 0;
  right: 0; */
`

const BlogPreviewTitle = styled(Heading3)`
  margin: 0 0 1rem 0;
  font-family: Amatic SC;
  font-size: 1.875rem;
`

function BlogPostPreview (props) {
  return (
    <BlogPostLink to={getBlogUrl(props.publishedAt, props.slug.current)}>
      <LeadMediaThumb>
        {props.mainImage && props.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .url()}
            alt={props.mainImage.alt}
          />
        )}
      </LeadMediaThumb>
      <BlogPreviewDescription className='postDescription'>
        <BlogPreviewTitle>{props.title}</BlogPreviewTitle>
        {props._rawExcerpt && (
          <Excerpt>
            <BlockText blocks={props._rawExcerpt} />
          </Excerpt>
        )}
      </BlogPreviewDescription>
    </BlogPostLink>
  )
}

export default BlogPostPreview

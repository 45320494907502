import { Link } from 'gatsby'
import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import BlogPostPreview from './blog-post-preview'
import styled from 'styled-components'
import { device } from '../styles/MediaQueries'
import { typography } from '../styles/Typography'
import { colors } from '../styles/Variables'

gsap.registerPlugin(ScrollTrigger, SplitText)

const StyledBlogGrid = styled.div`
  margin: 2em 0 4em; 
`

const Headline = styled.h2`
  font-size: var(--font-small-size);
  line-height: var(--font-small-line-height);
  margin: 2rem 0;

  & a {
    color: inherit;
    text-decoration: none;
  }
`

const Grid = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2em;
`

const BrowseMoreNav = styled.div`
 ${typography.small}
  margin-top: 1rem;
  text-align: right;

  & a {
    display: inline-block;
    padding: 0.5rem 0;
    color: inherit;
    text-decoration: none;

    @media (hover: hover) {
      &:hover {
        color: ${colors.colorAccent};
      }
    }
  }
`

const BlogListItem = styled.li`
  visibility: hidden;
`

function BlogPostPreviewGrid (props) {
  const revealRefs = useRef([])
  revealRefs.current = []

  // Where did I get this code from? iHateTomatoes somehow.
  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  const recipeItems = revealRefs.current

  useEffect(() => {
    gsap.set(recipeItems, { y: 100, visibility: 'hidden' })
    setTimeout(() => {
      gsap.set(recipeItems, { y: 100 })
      ScrollTrigger.batch(recipeItems, {
        start: 'top 85%',
        onEnter: batch => gsap.to(batch, { ease: 'back', autoAlpha: 1, y: 0, stagger: { each: 0.075, grid: [1, 3] }, overwrite: true })
      })
    }, 350)
  }, [])

  const pageTitle = useRef(null)

  useEffect(() => {
    gsap.from(pageTitle.current, {
      autoAlpha: 0,
      scale: 0.5,
      ease: 'back',
      duration: 0.875,
      yPercent: '40'
    })
  }, [])

  return (
    <StyledBlogGrid>
      {props.title && (
        <Headline>
          {props.browseMoreHref ? (
            <Link to={props.browseMoreHref} ref='pageTitle'>{props.title}</Link>
          ) : (
            props.title
          )}
        </Headline>
      )}
      <Grid>
        {props.nodes &&
          props.nodes.map(node => (
            <BlogListItem key={node.id} ref={addToRefs}>
              <BlogPostPreview {...node} />
            </BlogListItem>
          ))}
      </Grid>
      {props.browseMoreHref && (
        <BrowseMoreNav>
          <Link to={props.browseMoreHref}>Browse more</Link>
        </BrowseMoreNav>
      )}
    </StyledBlogGrid>
  )
}

BlogPostPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default BlogPostPreviewGrid
